import React from 'react';
import './Header.css'; // Assuming you have a CSS file for styling

const Header = () => {
  return (
    <header className="header">
      <img src="/logo192.png" alt="Logo" className="logo" />
      <h1 className="title">Light of Youth</h1>
      <div className="live-indicator">
        <span>Live</span>
        <div className="rec-icon"></div>
      </div>
    </header>
  );
};

export default Header;
