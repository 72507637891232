import { useEffect } from 'react';
// import ChatBox from './ChatBox';

const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};

function VideoPlayer() {
  useEffect(() => {
    loadScript('//play.streamingvideoprovider.com/js/dplayer.js')
      .then(() => {
        const vars = {
          clip_id: "qr2rzj7my00g",
          // ... other variables ...
        };
        const svp_player = new window.SVPDynamicPlayer("svp_playerqr2rzj7my00g", "", "100%", "100%", { use_div: "svp_playerqr2rzj7my00g", skin: "3" }, vars);
        svp_player.execute();
      })
      .catch(err => console.error('Script loading error: ', err));
  }, []);

  return (
    <div>
    <div id="svp_playerqr2rzj7my00g" style={{ width: '100%', height: '400px' }}>
    </div>
    {/* <ChatBox/> */}
    </div>
  );
}

export default VideoPlayer;
  
