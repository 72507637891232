// import logo from './logo.svg';
import Header from "./Header";
import VideoPlayer from "./VideoPlayer"
import './index.css';

function App() {
  return (
    <div className="App">
      <Header />
      <body>
        <VideoPlayer/>
      </body>
    </div>
  );
}

export default App;
